<template>
  <div>
    <div class="text-center mb-2 text-primary">
      <b>
        แนวทางในการถอนเงินจากบัญชี Ongphra Wallpaper Store เข้าธนาคารของคุณ ต้องเป็นไปตามจำนวนเงินขั้นต่ำที่ถอนได้
        โดยการโอนเงินอาจใช้เวลาดำเนินการนานถึง 2 วันทำการ
      </b>
    </div>
    <b-card no-body>
      <div class="p-2">
        <dl class="row">
          <dt class="col-sm-4 my-auto">
            โอนเข้าบัญชี
          </dt>
          <dd class="col-sm-8">
            <span v-if="!loading">
              {{ form.bank_info.bank_type }} {{ form.bank_info.bank_no }} {{ form.bank_info.account }}
            </span>
            <b-skeleton v-else width="50%" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-6 col-sm-4">
            ยอดเงินที่ถอนได้
          </dt>
          <dd class="col-6 col-sm-8">
            <span v-if="!loading">{{ gFormatNumberToCurrency(form.user_info.credit_balance || 0) }} บาท</span>
            <b-skeleton v-else width="20%" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4 my-auto">
            <p class="mb-0">
              จำนวนเงินที่ต้องการถอน
            </p>
            <small class="text-danger">*ขั้นต่ำในการถอน 100 บาท</small>
          </dt>
          <dd class="col-sm-8">
            <b-input
              v-model="priceWithdraw"
              :disabled="loading || !canWithdraw || !$store.getters['AuthStore/_isCanAccessSomeFeature']"
              class="w-100"
              placeholder="ระบุจำนวนเงิน (บาท)"
              type="number"
              number
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4 my-auto">
            <del v-if="Number(form.withholding) === 0">หัก ณ ที่จ่าย ({{ form.withholding }}%)</del>
            <span v-else> หัก ณ ที่จ่าย ({{ form.withholding }}%) </span>
          </dt>
          <dd class="col-sm-8 text-danger">
            <span v-if="!loading">{{ gFormatNumberToCurrency(cTax) }} บาท</span>
            <b-skeleton v-else width="20%" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4 my-auto">
            ยอดสุทธิ <small class="text-muted">(หัก ณ ที่จ่าย + ค่าธรรมเนียมในการถอน)</small>
          </dt>
          <dd class="col-sm-8 text-success">
            <span v-if="!loading">{{ gFormatNumberToCurrency(cTotalAfterTax) }} บาท</span>
            <b-skeleton v-else width="20%" />
          </dd>
        </dl>
      </div>
      <hr />
      <div class="">
        <b class="text-center d-block mb-1">ค่าธรรมเนียมในการถอน</b>
        <b-table :items="itemsPreviewRuleWithdraw" :fields="fieldsPreviewRuleWithDraw" bordered>
          <template #cell(fee)="data">
            {{ renderFormatVat(data.value) }}
          </template>
        </b-table>
      </div>
      <div class="p-2 ml-auto">
        <button-widget
          text="ยืนยันการถอน"
          variant="success"
          :disabled="loading || !canWithdraw || !$store.getters['AuthStore/_isCanAccessSomeFeature']"
          :loading="btnLoading"
          @click="clickConfirmWithdraw"
        />
      </div>
    </b-card>
    <p class="text-right">
      *หากมีข้อสงสัย สามารถติดต่อผู้ดูแลระบบได้ที่
      <b-link href="https://lin.ee/t3FGSRx" target="_blank">
        ติดต่อสอบถาม
      </b-link>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsPreviewRuleWithdraw: [],
      fieldsPreviewRuleWithDraw: [
        {
          key: 'title',
          label: 'จำนวนเงินที่ถอน',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'fee',
          label: 'ค่าธรรมเนียม/รายการ',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      priceWithdraw: null,
      loading: false,
      form: {},
      btnLoading: false,
      canWithdraw: true,
    }
  },
  computed: {
    cTax() {
      if (this.priceWithdraw < 100) return 0

      if (this.priceWithdraw) {
        return this.priceWithdraw * (this.form?.withholding / 100)
      }
      return 0
    },
    cTotalAfterTax() {
      if (this.priceWithdraw < 100) return 0
      if (this.priceWithdraw) {
        const vat = this.calVatFormList(this.itemsPreviewRuleWithdraw, this.priceWithdraw)
        return this.priceWithdraw - this.cTax - vat
      }
      return 0
    },
  },
  created() {
    this.getWithdrawInfo()
  },
  methods: {
    async clickConfirmWithdraw() {
      if (this.priceWithdraw < 100) {
        this.gDisplayToast('โปรดตรวจสอบยอดเงินที่ต้องการถอน', 'ขั้นต่ำในการถอนคือ 100 บาท', 'danger')
        return
      }

      if (this.priceWithdraw > Number(this.form.user_info.credit_balance)) {
        this.gDisplayToast('โปรดตรวจสอบยอดเงินที่ต้องการถอน', 'ยอดเงินไม่พอสำหรับถอน', 'danger')

        return
      }

      const isConfirm = await this.gCheckConfirmV1(
        'ยืนยันขอถอนเงิน',
        `ยอดเงินสุทธิที่คุณจะได้รับคือ ${this.gFormatNumberToCurrency(this.cTotalAfterTax)} บาท `,
      )

      if (!isConfirm) return
      this.btnLoading = true
      const resp = await this.api
        .post('api/partner/withdraw/request', { amount: Number(this.priceWithdraw) })
        .catch(() => null)
      // console.log('clickConfirmWithdraw', resp)

      this.btnLoading = false

      if (resp && !`${resp}`.includes('กรุณารอการตรวจสอบ')) {
        const { credit_balance } = this.form.user_info
        this.form.user_info.credit_balance = Number(credit_balance) - Number(this.priceWithdraw)
        this.priceWithdraw = null
        this.canWithdraw = false
        this.gDisplayToast('ส่งคำขอถอนเงินสำเร็จแล้ว')
      } else {
        this.gDisplayToast('ส่งคำขอถอนเงินไม่สำเร็จแล้ว', 'โปรดลองใหม่อีกครั้ง', 'danger')
      }
    },
    async getWithdrawInfo() {
      this.loading = true

      const resp = await this.api.get('api/partner/withdraw/info').catch(() => null)
      // console.log('getWithdrawInfo', resp)

      if (resp) {
        this.form = { ...resp }
        this.canWithdraw = !resp.hasWithholding
        this.itemsPreviewRuleWithdraw = resp.withdrawal_fee
      }
      this.loading = false
    },
    renderFormatVat(value) {
      if (Number(value) === 0) return 'ไม่มีค่าธรรมเนียม'

      return this.gFormatNumberToCurrency(value)
    },
    checkVatStepTarget(symbol, target, value) {
      switch (symbol) {
        case '<':
          return value < target
        case '>=':
          return value >= target
        case '>':
          return value > target
        default:
          return false
      }
    },
    calVatFormList(list = [], price) {
      let vat = 0

      for (let index = 0; index < list.length; index += 1) {
        const { symbol, fee, amount } = list[index]

        if (this.checkVatStepTarget(symbol, Number(amount), Number(price))) {
          vat = Number(fee)
          // console.log('call', vat)
          break
        }
      }
      return vat
    },
  },
}
</script>

<style lang="scss" scoped></style>
